<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<!-- <div class="col-md-6">
				<b>Cuenta de retiro</b>
				<a-select style="width: 100%" v-model="destination">
					<a-select-option value="pacific">Azul</a-select-option>
					<a-select-option value="rls">Gris</a-select-option>
				</a-select>
			</div> -->
			<div class="col-md-4">
				<b>Tipo de recibo</b>
				<br />
				<a-select show-search v-model="taxes.type" style="width: 100%">
					<a-select-option value="infonavit"> Infonavit </a-select-option>
					<a-select-option value="imss"> IMSS </a-select-option>
					<a-select-option value="referenciados"> Impuestos referenciados (IVA-ISR) </a-select-option>
					<a-select-option value="estatal"> Estatal </a-select-option>
					<a-select-option value="municipal"> Municipal </a-select-option>
					<a-select-option value="cfe"> CFE (Comisión Federal de Electricidad)</a-select-option>
					<a-select-option value="nomina"> Nómina </a-select-option>
					<a-select-option value="nomina-vales"> Nómina-Vales </a-select-option>
				</a-select>
			</div>
			<div class="col-md-4">
				<b>Monto de pago</b>
				<a-input autocomplete="off" prefix="$" v-money="money" v-model="taxes.amount" />
			</div>
			<div class="col-md-4">
				<b>Formato de pago</b>
				<br />
				<a-upload name="file" accept="application/pdf,image/*" :file-list="filePayment" :remove="handleRemovePayment" :before-upload="beforeUploadPayment">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import { VMoney } from 'v-money'
import numeral from 'numeral'
import Swal from 'sweetalert2'
//
export default {
	name: 'taxesODCComponent',
	directives: {
		money: VMoney,
	},
	components: {},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('purchaseOrders', ['spinnerLoaderLabel', 'spinnerLoader']),
	},
	data() {
		return {
			taxes: {
				amount: 0,
				type: '',
			},
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			filePayment: [],
			destination: '',
		}
	},
	methods: {
		handleRemovePayment() {
			this.filePayment = []
		},
		beforeUploadPayment(file) {
			this.filePayment = [file]
			return false
		},
		async onSaveRecord() {
			//
			let errors = []

			if (this.taxes.type == '') {
				errors.push(`- Selecciona tipo de impuesto`)
			}
			if (numeral(this.taxes.amount).value() < 1) {
				errors.push(`- Ingresa monto a pagar`)
			}
			if (this.filePayment.length == 0) {
				errors.push(`- Ingresa el formato de pago.`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			} else {
				//
				let taxAmount = numeral(this.taxes.amount).value()
				let formData = false
				formData = new FormData()
				formData.append('file', this.filePayment[0])
				formData.append('amount', taxAmount)
				formData.append('taxType', this.taxes.type)

				let payload = {
					total: taxAmount,
					elements: [
						{
							measurement_unit: 'service',
							quantity: 1,
							unit_cost: taxAmount,
							description: `Pago referenciado - ${this.taxes.type.toUpperCase()}`,
						},
					],
					blocked: true,
					status: 1,
					is_tax: 1,
					destination: this.destination,
				}

				await this.$store.dispatch('purchaseOrders/CREATE', { payload, formData })
				this.$emit('close')
			}
		},
	},
	watch: {
		visible(isVisible) {
			if (!isVisible) {
				this.taxes = {
					amount: 0,
					type: '',
				}
				this.filePayment = []
			}
		},
	},
}
</script>
