<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-6">
				<b>Monto</b>
				<a-input autocomplete="off" prefix="$" v-money="money" v-model="amount" />
			</div>
			<div class="col-md-6">
				<b>Comprobante</b>
				<br />
				<a-upload name="file" accept="application/pdf,image/*" :file-list="file" :remove="handleRemovePayment" :before-upload="beforeUploadPayment">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import { VMoney } from 'v-money'
import numeral from 'numeral'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'
//
export default {
	name: 'modalCashflowComponent',
	directives: {
		money: VMoney,
	},
	components: {},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('cashFlow', ['spinnerLoaderLabel', 'spinnerLoader']),
	},
	data() {
		return {
			amount: 0,
			money: {
				decimal: '.',
				thousands: ',',
				precision: 0,
			},
			file: [],
			destination: '',
		}
	},
	methods: {
		handleRemovePayment() {
			this.file = []
		},
		beforeUploadPayment(file) {
			this.file = [file]
			return false
		},
		async onSaveRecord() {
			//
			let errors = []

			if (numeral(this.amount).value() == 0) {
				errors.push(`- Ingresa monto`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			} else {
				//
				this.$store.dispatch('warehouseCashflow/BUDGET_ALLOCATION', { amount: numeral(this.amount).value() }).then(async (response) => {
					await Swal.fire({
						title: 'Flujos de caja',
						text: utilities.objectValidate(response, 'message', 'Fondeo generado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					})

					// subimos comprobante
					if (this.file.length) {
						let formData = new FormData()
						formData.append('file', this.file[0])
						formData.append('id', response.data.warehouse_cashflow_id)
						formData.append('filetype', 'budgetAllocation')

						await this.$store.dispatch('cashFlow/UPLOAD_FILE', formData)
					}
					this.$emit('ok')
					this.$emit('close')
				})
			}
		},
	},
	watch: {
		visible(isVisible) {
			if (!isVisible) {
				this.amount = numeral(0).format('0,0')
				this.file = []
			}
		},
	},
}
</script>
